import React, { useState } from 'react';
import { UseControllerProps, useForm } from 'react-hook-form';
import { MASKS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { formatNumber } from '@archinsurance-viki/property-jslib/src/utils/converters';
import { useCarrierPoolsQuery, useSetFinalPremiumOverridesMutation, useToggleLayeredCarrierMutation } from '../../services/apiSlice';
import useQuoteId, { useQuoteFinalPremium, useQuotePolicyCoverage } from '../../hooks/quotes';
import { Carrier, CarrierPool, SetOverridesArgs } from '../../ts-types/ApiTypes';
import { useSubmissionIsEditable, useTransactionIsPremiumBearing as useIsPremiumBearingEndorsement } from '../../hooks/submissions';
import { useDebounceCallback } from '@archinsurance-viki/property-jslib/src/hooks/util';
import { PRICING_DEBOUNCE_TIME } from '../../constants/PricingConstants';
import _ from 'lodash';
import { BlurSubmitFormInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/BlurSubmitFormInput';
import { Switch } from '@archinsurance-viki/property-jslib/src/components/inputs/v2';
import { occurrenceDescription } from '../../features/coverage-terms/utils/coverage-utils';
import { useAppSelector } from '../../hooks/redux';
import { ErrorTooltip } from '../common/CommonTooltips';

type CarrierSharesWrapperProps = {
    children?: React.ReactElement;
    disabled?: boolean;
    isUpdating?: boolean;
    onResetCarriers?: () => void;
};
const CarrierSharesWrapper = ({ children, isUpdating, disabled, onResetCarriers }: CarrierSharesWrapperProps) => {
    return (
        <div className="flex column">
            <div className="header tw-flex tw-justify-end tw-py-1">
                {isUpdating && (
                    <div className="tw-flex tw-gap-3 tw-items-center tw-text-xs tw-justify-start tw-flex-1 tw-normal-case tw-font-normal">
                        Loading...
                        <div className="icon">
                            <div className="progress-indicator extra-small" />
                        </div>
                    </div>
                )}
                {onResetCarriers && (
                    <button disabled={disabled} onClick={onResetCarriers} className="text-button default">
                        Remove Overrides
                    </button>
                )}
            </div>
            <div className="tw-p-2">{children}</div>
        </div>
    );
};

type CarrierSharesRowProps = {
    carrier: Carrier;
    inputProps: UseControllerProps<FormValues>;
    onReset: () => void;
    onSubmit: () => void;
    error?: string;
    disabled?: boolean;
};

const CarrierSharesRow = ({ carrier, disabled, inputProps, error, onReset, onSubmit }: CarrierSharesRowProps) => (
    <>
        <label style={{ textAlign: 'left' }}>{carrier.carrier_short_name}</label>
        <div>{formatNumber(carrier.default_participation_pct, undefined, '%', 1)}</div>
        <div className="tw-flex tw-gap-1">
            <div className={error ? 'tw-visible' : 'tw-invisible'}>
                <ErrorTooltip error={error} />
            </div>
            <BlurSubmitFormInput<FormValues>
                className="tw-max-w-[55px] pricing-row-height"
                {...inputProps}
                disabled={disabled}
                maskOptions={MASKS['PERCENTAGE_POSITIVE']}
                onSubmit={onSubmit}
                onReset={onReset}
                percentAfter
            />
        </div>
        <div>{formatNumber(carrier.actual_allocated_premium, undefined, undefined)}</div>
    </>
);

type FormValues = Record<string, Record<`${number}`, { override_participation_pct: string | null }>> & {
    split_point: string;
    layer_a_premium_override: string;
    layer_b_premium_override: string;
};
const formDefaults = (carrierPools: CarrierPool[], split_point: number, layer_a_premium_override: string, layer_b_premium_override: string) => {
    const defaultValues = {};
    carrierPools.forEach(pool => {
        if (!pool.is_active_for_quote) {
            return;
        }
        defaultValues[pool.pool_id] = {};
        pool.carriers.forEach(carrier => {
            defaultValues[pool.pool_id][carrier.carrier_id] = { override_participation_pct: carrier.override_participation_pct ?? '' };
        });
    });
    defaultValues['split_point'] = split_point?.toFixed(0) ?? '';
    defaultValues['layer_a_premium_override'] = layer_a_premium_override ? (+layer_a_premium_override)?.toFixed(0) : '';
    defaultValues['layer_b_premium_override'] = layer_b_premium_override ? (+layer_b_premium_override)?.toFixed(0) : '';
    return defaultValues as FormValues;
};

const VALIDATE_ERROR_MAP = {
    exceedsMax: {
        value: (v: unknown) => +v <= 100,
        message: 'Cannot exceed 100%',
    },
};

const CarrierSharesPanel = () => {
    const quoteId = useQuoteId();
    const isEditable = useSubmissionIsEditable();
    const isPremiumBearingEndorsement = useIsPremiumBearingEndorsement();
    const [setOverrides, { isLoading: isCarrierUpdating }] = useSetFinalPremiumOverridesMutation({ fixedCacheKey: `${quoteId}` });
    const [triggerLayered] = useToggleLayeredCarrierMutation();
    const layeredCarrierEnabled = useAppSelector(state => state.global.featureFlags?.layered_carrier_participation_carrier_shares_panel_enabled);
    const LAYERED_CARRIER_INITIAL_EFFECTIVE_DATE = useAppSelector(state => state.global.CONSTANTS.LAYERED_CARRIER_INITIAL_EFFECTIVE_DATE);

    const { data: pcData } = useQuotePolicyCoverage();
    const { finalCarrierData, finalPremium, data: quoteData, isLoading: isLoadingQuoteData } = useQuoteFinalPremium();

    const debouncedSetOverrides = useDebounceCallback(
        (data: Partial<Pick<SetOverridesArgs, 'carrier_participations' | 'split_point'>>) => setOverrides({ id: finalPremium.id, quoteId, data }),
        PRICING_DEBOUNCE_TIME
    );
    const { data, isLoading, isFetching, isUninitialized } = useCarrierPoolsQuery(
        { quoteId },
        {
            skip: !quoteId,
        }
    );
    const [prevData, setPrevData] = useState(data);

    const { split_point, layer_1_premium, layer_2_premium, policy_coverage } = pcData ?? {};
    const { effective_date, equipment_breakdown, layer_a_premium_override, layer_b_premium_override } = policy_coverage ?? {};
    const { control, formState, handleSubmit, reset, resetField } = useForm<FormValues>({
        defaultValues: formDefaults(data?.carrier_pools ?? [], split_point ?? null, layer_a_premium_override, layer_b_premium_override),
    });
    const { dirtyFields, errors } = formState;

    if (data && !_.isEqual(data, prevData)) {
        reset(formDefaults(data.carrier_pools, pcData?.split_point, layer_a_premium_override, layer_b_premium_override));
        setPrevData(data);
    }

    const disabled = !isEditable;

    const handleResetCarriers = () => {
        reset();
        debouncedSetOverrides({ carrier_participations: [] });
    };

    if (!pcData) {
        return null;
    }

    if (isLoading || isUninitialized || isLoadingQuoteData) {
        return <CarrierSharesWrapper disabled isUpdating />;
    }

    if (!finalCarrierData) {
        return (
            <CarrierSharesWrapper isUpdating={isFetching || isCarrierUpdating} disabled={disabled} onResetCarriers={handleResetCarriers}>
                <div className="padding-light">No eligible carriers.</div>
            </CarrierSharesWrapper>
        );
    }

    const hasMultipleActivePools = data.carrier_pools.filter((pool: CarrierPool) => pool.is_active_for_quote).length > 1;

    const onSubmit = async (data: FormValues) => {
        const cleanSubmitData = [];
        const { split_point, layer_a_premium_override, layer_b_premium_override, ...restDirtyFields } = dirtyFields;
        Object.keys(restDirtyFields).forEach(poolId => {
            Object.keys(restDirtyFields[poolId]).forEach(carrierId => {
                if (!carrierId) {
                    return;
                }
                const { override_participation_pct } = data[poolId][carrierId];
                const cleanOverridePct = override_participation_pct === '' ? null : +override_participation_pct;
                cleanSubmitData.push({
                    pool_id: +poolId,
                    carrier_id: +carrierId,
                    override_participation_pct: cleanOverridePct,
                });
            });
        });

        const submit = {};
        if (cleanSubmitData.length > 0) {
            submit['carrier_participations'] = cleanSubmitData;
        }
        (['split_point', 'layer_a_premium_override', 'layer_b_premium_override'] as const).forEach(key => {
            if (key in dirtyFields) {
                const value = data[key];
                submit[key] = value === '' ? null : value.replace(/,/g, '');
                if (key === 'split_point' && value !== '') {
                    submit[key] = +submit[key]; // convert to number
                }
            }
        });
        debouncedSetOverrides(submit);
    };

    const getLabel = (pool: CarrierPool) => {
        const { amount_subject } = quoteData.final_premium;
        const { split_point } = pcData;
        let limit = pool.pool_name === 'LAYER_A' ? split_point : +amount_subject - split_point;
        let attachment = pool.pool_name === 'LAYER_B' ? split_point : 0;
        let description = occurrenceDescription(true, limit > 0 ? limit : 0, 100, attachment).replace('Primary', 'Per Occurrence');
        if (description.search('Per Occurrence') === -1) {
            description = `${description} Per Occurrence`;
        }
        return description;
    };

    const hasLayeredCarrierEffectiveDate =
        effective_date && LAYERED_CARRIER_INITIAL_EFFECTIVE_DATE && new Date(effective_date) >= new Date(LAYERED_CARRIER_INITIAL_EFFECTIVE_DATE);
    const showLayeredCarrierUi = quoteData.is_carrier_layered || (layeredCarrierEnabled && hasLayeredCarrierEffectiveDate);

    return (
        <CarrierSharesWrapper isUpdating={isFetching || isCarrierUpdating} disabled={disabled} onResetCarriers={handleResetCarriers}>
            <form onSubmit={handleSubmit(onSubmit)} className="tw-flex tw-flex-col tw-gap-2">
                {showLayeredCarrierUi && (
                    <div className="tw-grid tw-grid-cols-2 tw-gap-1 tw-w-[200px]">
                        <>
                            <label>Split into Layers</label>

                            <Switch
                                disabled={isPremiumBearingEndorsement || disabled}
                                value={quoteData.is_carrier_layered}
                                onChange={() => triggerLayered({ quoteId, isLayered: !quoteData.is_carrier_layered })}
                            />
                        </>
                        {quoteData.is_carrier_layered && (
                            <>
                                <label className="tw-self-center">Split Point</label>
                                <BlurSubmitFormInput<FormValues>
                                    className="tw-max-w-[70px] tw-justify-self-start tw-self-center pricing-row-height"
                                    name="split_point"
                                    control={control}
                                    disabled={disabled}
                                    maskOptions={MASKS['CURRENCY_INTEGER']}
                                    onReset={() => resetField('split_point')}
                                    onSubmit={handleSubmit(onSubmit)}
                                />
                            </>
                        )}
                    </div>
                )}
                <div>
                    {data.carrier_pools.map(
                        pool =>
                            pool.is_active_for_quote && (
                                <React.Fragment key={pool.pool_id}>
                                    {hasMultipleActivePools && (
                                        <>
                                            {(quoteData.is_carrier_layered || pool.display_name) && (
                                                <h2 className="tw-font-semibold tw-col-span-full tw-mt-1 tw-mb-1.5">
                                                    {quoteData.is_carrier_layered ? `${pool.display_name}: ${getLabel(pool)}` : pool.display_name}
                                                </h2>
                                            )}
                                            {quoteData.is_carrier_layered && (
                                                <>
                                                    <div className="grid-layerpremium">
                                                        <div className="tw-mr-20" />
                                                        <div className="sub-header">Default</div>
                                                        <div className="sub-header">Override</div>
                                                    </div>
                                                    <div className="grid-layerpremium">
                                                        <div className="sub-header">Layer Premium</div>
                                                        <div>{pool.pool_name === 'LAYER_A' ? layer_1_premium : layer_2_premium}</div>
                                                        <BlurSubmitFormInput<FormValues>
                                                            className="tw-max-w-[70px] tw-justify-self-start tw-self-center pricing-row-height"
                                                            name={pool.pool_name === 'LAYER_A' ? 'layer_a_premium_override' : 'layer_b_premium_override'}
                                                            control={control}
                                                            disabled={disabled}
                                                            maskOptions={MASKS['CURRENCY_INTEGER']}
                                                            onReset={() =>
                                                                resetField(
                                                                    pool.pool_name === 'LAYER_A' ? 'layer_a_premium_override' : 'layer_b_premium_override'
                                                                )
                                                            }
                                                            onSubmit={handleSubmit(onSubmit)}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                    <div className="grid-carriersharespanel">
                                        <div className="" />
                                        <div className="sub-header">Default</div>
                                        <div className="sub-header">Override</div>
                                        <div className="sub-header">Premium</div>
                                        <div className="tw-col-span-full tw-border-t tw-border-grey-border" />
                                        {pool.carriers.map(carrier => (
                                            <CarrierSharesRow
                                                key={carrier.carrier_id}
                                                inputProps={{
                                                    name: `${pool.pool_id}.${carrier.carrier_id}.override_participation_pct`,
                                                    control,
                                                    rules: {
                                                        validate: {
                                                            exceedsMax: VALIDATE_ERROR_MAP.exceedsMax.value,
                                                        },
                                                    },
                                                }}
                                                error={
                                                    VALIDATE_ERROR_MAP[errors?.[pool.pool_id]?.[carrier.carrier_id]?.override_participation_pct?.type]?.message
                                                }
                                                carrier={carrier}
                                                disabled={disabled}
                                                onSubmit={handleSubmit(onSubmit)}
                                                onReset={() => resetField(`${pool.pool_id}.${carrier.carrier_id}.override_participation_pct`)}
                                            />
                                        ))}
                                        {hasMultipleActivePools && <div className="tw-col-span-full tw-mb-2 last:tw-mb-0" />}
                                    </div>
                                </React.Fragment>
                            )
                    )}
                </div>

                {equipment_breakdown && (
                    <>
                        <div className="tw-col-span-full tw-justify-self-start tw-flex tw-items-center tw-gap-[1.25rem] tw-mb-1">
                            <div className="flex column">
                                <div className="header">
                                    <label>CARRIER SHARES - EB </label>
                                </div>
                            </div>
                        </div>
                        <div className="grid-carriersharespanel">
                            <div className="" />
                            <div className="sub-header">Default</div>
                            <div className="sub-header">Override</div>
                            <div className="sub-header">Premium</div>
                            <div className="tw-col-span-full tw-border-t tw-border-grey-border" />
                            <label style={{ textAlign: 'left' }}>ARCH</label>
                            <div>{formatNumber(100, undefined, '%', 1)}</div>
                            <div className="tw-flex tw-gap-1"></div>
                            <div>{formatNumber(finalPremium.equipment_breakdown_premium_adj_for_participation_final, undefined, undefined, 2)}</div>
                            <div className="tw-col-span-full tw-mb-2 last:tw-mb-0" />
                        </div>
                    </>
                )}
            </form>
        </CarrierSharesWrapper>
    );
};

export default CarrierSharesPanel;
