import { useQuoteFinalPremium } from '../../hooks/quotes';
import Grid, { Label, Value } from './PricingGrid';
import { getAmountSubjectRate, getTivRate } from './utils';

const TargetPremiumPanel = () => {
    const { finalPremium } = useQuoteFinalPremium();
    if (!finalPremium) {
        return null;
    }
    const { amount_subject, is_valid, tiv, target_minimum_charged_premium, charged_wdbd_premium, target_minimum_charged_premium_after_wdbd_premium } =
        finalPremium;
    const targetPremiumTivRate = is_valid ? getTivRate(target_minimum_charged_premium, tiv) : null;
    const targetPremiumAmountSubjectRate = is_valid ? getAmountSubjectRate(target_minimum_charged_premium, amount_subject) : null;
    const targetWdbdPremiumTivRate = is_valid ? getTivRate(target_minimum_charged_premium_after_wdbd_premium, tiv) : null;
    const targetWdbdPremiumAmountSubjectRate = is_valid ? getAmountSubjectRate(target_minimum_charged_premium_after_wdbd_premium, amount_subject) : null;

    return (
        <>
            <div className="info-block catastrophe-premium w-260">
                <div className="header">Target Premium</div>
                <Grid columns={2} className="gap-0125 padding-light">
                    <Label>
                        <b>Target Premium - Before WDBD</b>
                    </Label>
                    <Value bold format={{ type: 'number', delimiter: ',' }} value={target_minimum_charged_premium} />

                    <Label>Rate (TIV)</Label>
                    <Value format={{ type: 'number', decimals: 2 }} value={targetPremiumTivRate} />

                    <Label>Rate (Amount Subject)</Label>
                    <Value format={{ type: 'number', decimals: 2 }} value={targetPremiumAmountSubjectRate} />

                    <div className="grid-2-wide grid-spacer"></div>

                    <Label>
                        <b>Wind Deductible Buy Down Premium</b>
                    </Label>
                    <Value bold format={{ type: 'number', delimiter: ',' }} value={charged_wdbd_premium ?? 0} />

                    <div className="grid-2-wide grid-spacer"></div>

                    <Label>
                        <b>Target Premium - After WDBD</b>
                    </Label>
                    <Value bold format={{ type: 'number', delimiter: ',' }} value={target_minimum_charged_premium_after_wdbd_premium} />
                    <Label>Rate (TIV)</Label>
                    <Value format={{ type: 'number', decimals: 2 }} value={targetWdbdPremiumTivRate} />

                    <Label>Rate (Amount Subject)</Label>
                    <Value format={{ type: 'number', decimals: 2 }} value={targetWdbdPremiumAmountSubjectRate} />
                </Grid>
            </div>
        </>
    );
};

export default TargetPremiumPanel;
